/* eslint-disable @typescript-eslint/ban-ts-comment */
import {ethers} from "ethers-ts";
import constants from "./constants";
import {Primitives, Zenon} from "znn-ts-sdk";
import defaultZtsIcon from "./../assets/tokens/zts.svg";
import {decryptMessage, encryptMessage} from "./encryption";
import {mangle, unmangle} from "./mangling";

export const getZenonWalletInfo = async (zenon: Zenon, address: string) => {
  try {
    const znnAddressObject = Primitives.Address.parse(address);
    console.log("znnAddress", znnAddressObject);

    const getAccountInfoByAddress = await zenon.ledger.getAccountInfoByAddress(znnAddressObject);
    console.log("getAccountInfoByAddress", getAccountInfoByAddress);

    const plasma = await zenon.embedded.plasma.get(znnAddressObject);
    console.log("plasma", plasma);

    getAccountInfoByAddress.plasma = plasma;

    // console.log("updatedConstants after zenon data", updatedConstants);
    getAccountInfoByAddress.balanceInfoMap = await mapObject(getAccountInfoByAddress.balanceInfoMap, (tok: any) => {
      if (getAccountInfoByAddress.balanceInfoMap[tok.address]) {
        return {
          ...tok,
          balance: getAccountInfoByAddress.balanceInfoMap[tok.address].balance.toString(),
          balanceWithDecimals: ethers.utils.formatUnits(
            ethers.BigNumber.from((getAccountInfoByAddress.balanceInfoMap[tok.address]?.balance?.toString() || 0) + ""),
            ethers.BigNumber.from(getAccountInfoByAddress.balanceInfoMap[tok.address].token.decimals || 8)
          ),
          address: getAccountInfoByAddress.balanceInfoMap[tok.address].token.tokenStandard,
          symbol: getAccountInfoByAddress.balanceInfoMap[tok.address].token.symbol,
          decimals: getAccountInfoByAddress.balanceInfoMap[tok.address].token.decimals,
        };
      }
      return tok;
    });

    // getAccountInfoByAddress.balanceInfoMap = getAccountInfoByAddress.balanceInfoMap.map((tok: any) => {
    //   if (getAccountInfoByAddress.balanceInfoMap[tok.address]) {
    //     return {
    //       ...tok,
    //       balance: getAccountInfoByAddress.balanceInfoMap[tok.address].balance.toString(),
    //       address: getAccountInfoByAddress.balanceInfoMap[tok.address].token.tokenStandard,
    //       symbol: getAccountInfoByAddress.balanceInfoMap[tok.address].token.symbol,
    //       decimals: getAccountInfoByAddress.balanceInfoMap[tok.address].token.decimals,
    //     };
    //   }
    //   return tok;
    // });
    return getAccountInfoByAddress;
  } catch (err) {
    throw err;
  }
};

export const getZenonTokenInfo = async (tokenStandard: string, zenon: any) => {
  console.log("tokenStandard", tokenStandard);
  const tokStandard = Primitives.TokenStandard.parse(tokenStandard);
  console.log("tokStandard", tokStandard);
  console.log("tokStandard.toString()", tokStandard.toString());
  return await zenon.embedded.token.getByZts(tokStandard);
};

export const getInternalTokensDetails = (currentInternalTokens: any, zenon: any) => {
  return Promise.all(
    currentInternalTokens.map(async (tok: any) => {
      const tokenInfo = await getZenonTokenInfo(tok.address, zenon);
      console.log("ZNNtokenInfo", tokenInfo);
      return {
        ...tok,
        symbol: tokenInfo?.symbol,
        decimals: tokenInfo?.decimals,
      };
    })
  );
};

export const mapObject = async (obj: any, func: any) => {
  return Object.fromEntries(await Promise.all(Object.entries(obj).map(async ([k, v]) => [k, await func(v)])));
};

export const findInObject = (obj: any, func: any) => {
  const toArray = Object.keys(obj).map((key: string) => obj[key]);
  return toArray.find(func);
};

export const secondsToDuration = function (seconds: number) {
  //days
  const days = Math.floor(seconds / (24 * 3600));
  seconds -= days * 24 * 3600;

  //hours
  const hours = Math.floor(seconds / 3600);
  seconds -= hours * 3600;

  //minutes
  const minutes = Math.floor(seconds / 60);
  seconds -= minutes * 60;

  return `${days > 0 ? (days > 9 ? days : "0" + days) + " days" : ""} ${hours > 9 ? hours : "0" + hours}:${
    minutes > 9 ? minutes : "0" + minutes
  }:${seconds > 9 ? seconds.toFixed(0) : "0" + seconds.toFixed(0)}`;
};

export const encryptReferralCode = (code: string) => {
  // const key = "UjFpyVLBUHC8ZEVCEouAW5DLJZ3wrXzVFEicXAbHsd";
  // const encrypted = await encryptMessage(key, code);
  // console.log(encrypted);
  // return encrypted;

  // const key = "UjFpyVLBUHC";
  const mangled: string = mangle(code, constants.manglingKey);
  // console.log("mangled", mangled);
  return mangled;
};

export const decryptReferralCode = (code: string) => {
  // const key = "UjFpyVLBUHC8ZEVCEouAW5DLJZ3wrXzVFEicXAbHsd";
  // const decrypted = await decryptMessage(key, code);
  // console.log(decrypted);
  // return decrypted;

  // const key = "UjFpyVLBUHC";
  const unmangled: string = unmangle(code, constants.manglingKey);
  // console.log("unmangled", unmangled);
  return unmangled;
};

export const getRefferalCode = () => {
  const mangledCode = localStorage.getItem("znn-referral-code");
  if (mangledCode) {
    return decryptReferralCode(mangledCode);
  } else {
    return "";
  }
};

export const setRefferalCodeAddress = (address: string) => {
  const encryptedCode = encryptReferralCode(address);
  if (encryptedCode) {
    return localStorage.setItem("znn-referral-code", encryptedCode);
  } else {
    throw "Unable to encrypt referral code";
  }
};
