const constants = {
  isMainNet: true,
  isDevNet: false,
  isTestNet: false,

  bridgeBaseUrl: "https://bridge.mainnet.zenon.community/?referral=",
  manglingKey: "UjFpyVLBUHC",
  defaultMangledReferralCode: "2f5b37021f372129632f772c1f2d011d3e7437313b74225d27494f267f3b2d3a766c1e3f140a307e",
};

//
//
// TEST ENV CONSTANTS
//
//
const testConstants = {
  ...constants,
  isMainNet: false,
  isDevNet: false,
  isTestNet: true,

  bridgeBaseUrl: "https://bridge.testnet.zenon.community/?referral=",
  manglingKey: "UjFpyVLBUHC",
  defaultMangledReferralCode: "2f5b37021f372129632f772c1f2d011d3e7437313b74225d27494f267f3b2d3a766c1e3f140a307e",
};

//
//
// DEV ENV CONSTANTS
//
//

const developmentConstants = {
  ...constants,
  isMainNet: false,
  isDevNet: true,
  isTestNet: false,

  bridgeBaseUrl: "https://bridge.testnet.zenon.community/?referral=",
  manglingKey: "UjFpyVLBUHC",
  defaultMangledReferralCode: "2f5b37021f372129632f772c1f2d011d3e7437313b74225d27494f267f3b2d3a766c1e3f140a307e",
};

let exportedConstants = constants;

console.log("process.env.NODE_ENV", process.env.NODE_ENV);
console.log("process.env.REACT_APP_NETWORK_ENV", process.env.REACT_APP_NETWORK_ENV);

if (process.env.REACT_APP_NETWORK_ENV === "production") exportedConstants = constants;
if (process.env.REACT_APP_NETWORK_ENV === "development") exportedConstants = developmentConstants;
if (process.env.REACT_APP_NETWORK_ENV === "test") {
  exportedConstants = testConstants;
}

export default exportedConstants;
