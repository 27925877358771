import React from "react";
import "./wizardLayout.scss";
import ReferralLinkGeneration from "../../pages/wizardSteps/referralLinkGeneration/referralLinkGeneration";

const WizardLayout = () => {
  return (
    <div className="wizard-container">
      <div className="container-header">
        <div className="logo-container">
          <div className="logo-white-part">NoM</div>
          <div className="logo-green-part">AffiliateZ</div>
        </div>
      </div>
      <div id="extension-approval-spinner-root" className="wizard-content">
        <ReferralLinkGeneration />
      </div>
    </div>
  );
};

export default WizardLayout;
