import React, {useEffect} from "react";
import WizardLayout from "../wizardLayout/wizardLayout";
import "./mainLayout.scss";
import swirl from "./../../assets/swirl.svg";
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {SpinnerProvider} from "../../services/hooks/spinner/spinnerContext";
import CustomCursor from "../../components/custom-cursor/custom-cursor";
import {addBeforeUnloadEvents, removeBeforeUnloadEvents} from "../../services/pageHandlers/pageHandlers";
import InfoBanner from "../../components/info-banner/info-banner";
import ReferralCodeInterpreter from "../../components/referralCodeInterpreter/referralCodeInterpreter";

const MainLayout = () => {
  useEffect(() => {
    addBeforeUnloadEvents();
    return () => {
      removeBeforeUnloadEvents();
    };
  });

  return (
    <div className="main-layout">
      <SpinnerProvider>
        {/* <NavMenuBar /> */}
        <div className="bg-shapes-container">
          <img alt="bg-shapes" className="bg-shapes" src={require("./../../assets/bg-shapes.png")}></img>
          <img alt="bg-swirl" className="bg-swirl" src={swirl}></img>
        </div>
        <CustomCursor></CustomCursor>
        <div className="responsive-container">
          <WizardLayout />
          <ToastContainer />
          <InfoBanner />
          {/* <ReferralCodeInterpreter /> */}
        </div>
      </SpinnerProvider>
      <div id="spinner-root"></div>
    </div>
  );
};

export default MainLayout;
