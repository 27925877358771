import React, {useState} from "react";
import {useForm} from "react-hook-form";
import {encryptReferralCode} from "../../../utils/utils";
import {Primitives} from "znn-ts-sdk";
import {toast} from "react-toastify";
import infoIcon from "./../../../assets/info-icon.svg";
import infoIconRed from "./../../../assets/info-icon-red.svg";
import infoIconBlue from "./../../../assets/info-icon-blue.svg";
import copyIcon from "./../../../assets/copy-icon-white.svg";
import pasteIcon from "./../../../assets/paste-icon-white.svg";
import constants from "../../../utils/constants";

// eslint-disable-next-line @typescript-eslint/no-empty-function
const ReferralLinkGeneration = () => {
  const {
    register,
    control,
    handleSubmit,
    formState: {errors},
    reset,
    setValue,
  } = useForm({mode: "onChange"});
  const [zenonAddress, setZenonAddress] = useState("");
  const [referralLink, setReferralLink] = useState("");
  const [iframeCode, setIframeCode] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const onFormSubmit = async () => {
    console.log("referrerAddress", zenonAddress);
    try {
      if (Primitives.Address.parse(zenonAddress)) {
        const encrypted = encryptReferralCode(zenonAddress);
        console.log("encrypted", encrypted);
        setReferralLink(constants.bridgeBaseUrl + encrypted);

        setIframeCode(`
        <iframe src="${constants.bridgeBaseUrl}${encrypted}" style="width: 0px; height: 0px; position: fixed; top: -300px; left: -300px; overflow: hidden"></iframe>
        `);

        toast(`Referral link generated !`, {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          type: "success",
          theme: "dark",
          toastId: "validZenonAddress",
        });
      }
    } catch (err) {
      console.error("Invalid Zenon Address");
      toast("Invalid Zenon Address", {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        type: "error",
        theme: "dark",
        toastId: "invalidZenonAddress",
      });
    }
  };

  const copyLink = () => {
    try {
      navigator.clipboard.writeText(referralLink);
      toast(`Link copied to clipboard`, {
        position: "bottom-center",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        type: "success",
        theme: "dark",
      });
    } catch (err) {
      console.error(err);
    }
  };

  const copyIframe = () => {
    try {
      navigator.clipboard.writeText(iframeCode);
      toast(`Iframe code copied to clipboard`, {
        position: "bottom-center",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        type: "success",
        theme: "dark",
      });
    } catch (err) {
      console.error(err);
    }
  };

  const pasteAddress = async () => {
    try {
      const paste = await navigator.clipboard.readText();
      setZenonAddress(paste);
      setValue("zenonAddress", paste, {shouldValidate: true});

      toast(`Address pasted from clipboard`, {
        position: "bottom-center",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        type: "success",
        theme: "dark",
      });
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div className="mt-2 w-100">
      {/* This iframe automatically sets the default referral code */}
      <iframe
        src={`${constants.bridgeBaseUrl}${constants.defaultMangledReferralCode}`}
        style={{width: "0px", height: "0px", position: "fixed", top: "-300px", left: "-300px", overflow: "hidden"}}
      ></iframe>

      <h2 className="text-center"> {`Let's get the people going 👽🖖`} </h2>

      <form onSubmit={handleSubmit(() => onFormSubmit())}>
        <div className="d-flex w-100 justify-content-around align-items-center mt-5">
          <div className="custom-control flex-1 min-width-100">
            <input
              {...register("zenonAddress", {required: true})}
              className={`w-100 h-100 custom-label ${errors.zenonAddress ? "custom-label-error" : ""}`}
              placeholder="Type your address"
              value={zenonAddress}
              onChange={(e) => {
                setZenonAddress(e.target.value);
                setValue("zenonAddress", e.target.value, {shouldValidate: true});
              }}
              type="text"
            ></input>
            <div className="input-label">{"Address"}</div>

            <div className={`input-error ${errors.zenonAddress?.type === "required" ? "" : "invisible"}`}>
              Address is required
            </div>
          </div>
          <div className="ml-2 referral-copy-button tooltip" onClick={() => pasteAddress()}>
            <img src={pasteIcon}></img>
            <span className="tooltip-text">
              Paste your ZNN Address here. You can generate a new address with the Syrius Wallet
            </span>
          </div>
        </div>
        <div
          className={`button primary w-100 mt-4 ${Object.keys(errors).length || isButtonDisabled ? "disabled" : ""}`}
        >
          {isButtonDisabled ? "Waiting for approval from wallet..." : "Generate Link"}
          <input className="ghost-over cursor-pointer" type="submit" name="submitButton"></input>
        </div>
      </form>

      {referralLink ? (
        <>
          <p className="text-primary text-bold mt-2 mb-0">This is your referral link: </p>
          <div className={`d-flex w-100 justify-content-around align-items-center mt-2`}>
            <div className="custom-control flex-1 min-width-100">
              <div className="custom-label w-100 referral-label active">
                <div className="referral-text">
                  <div className="text-white">{referralLink}</div>
                </div>
              </div>
            </div>
            <div className="ml-2 referral-copy-button" onClick={() => copyLink()}>
              <img src={copyIcon}></img>
            </div>
          </div>

          <div className="d-flex justify-content-start mt-2">
            <img alt="links-info" className="switch-arrow mr-1" src={infoIconRed}></img>
            <span className="">
              The referral link code <b>never expires</b>, first code applied will always remain as set and it cannot be
              overridden
            </span>
          </div>

          <div className="d-flex justify-content-start mt-2">
            <img alt="links-info" className="switch-arrow mr-1" src={infoIcon}></img>
            <span className="">
              We recommend you to <b>shorten</b> this link with this tool:
              <a className="ml-1" href="https://znn.link" rel="noreferrer noopener">
                <b className="text-primary">znn.link</b>
              </a>
            </span>
          </div>

          <p className="text-primary text-bold mt-4 mb-0">This is the embeddable iframe: </p>

          <div className={`d-flex w-100 justify-content-around align-items-center mt-2`}>
            <div className="custom-control flex-1 min-width-100">
              <div className="custom-label w-100 referral-label active">
                <div className="referral-text">
                  <div className="text-white">{iframeCode}</div>
                </div>
              </div>
            </div>
            <div className="ml-2 referral-copy-button" onClick={() => copyIframe()}>
              <img src={copyIcon}></img>
            </div>
          </div>

          <div className="d-flex justify-content-start mt-2">
            <img alt="links-info" className="switch-arrow mr-1" src={infoIconBlue}></img>
            <span className="">
              By embedding this iframe on your website, you can <b>automatically apply your referral code</b> in the
              bridge interface for users who visit your site.
            </span>
          </div>

          <div className="d-flex justify-content-start mt-2">
            <img alt="links-info" className="switch-arrow mr-1" src={infoIcon}></img>
            <span className="">
              When a user joins the NoM with your referral link <b>you will receive</b> 2% whenever that user unwraps
              wZNN or wQSR and that user will also receive a <b>1% cashback</b>.
            </span>
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default ReferralLinkGeneration;
